import * as React from 'react'
import Layout from '../../components/layout'
import Collections from '../../components/collections'
import "@fontsource/montserrat"
import {
  containerText
} from'../../lib/home.module.css'

const CollectionsPage = () => {
  return (
    <Layout pageTitle="Curtain Poles Collections">
      <div className={containerText}>
        <h2>Collections</h2>
        <p>Take a look at our beautiful hand finished collections, each and every step of the design process is completed here in London by our very own skilled team of artisans.</p>
        <p>Whilst our timber is sourced from outside the U.K., the process of creating the product from concept to crafting the final piece is all done on home soil. From classic wood grains to regal shaped finials and finishes, or if you're looking to add a pop of colour, we've got something for every Interior scheme at Byron &amp; Byron.</p>
      </div>
      <Collections />
   </Layout>
  )
}

export default CollectionsPage